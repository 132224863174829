import React, { useEffect, useState } from "react";
import {Button, Form, Input, message, Typography} from "antd";
import {Link, useNavigate} from "react-router-dom";
// import styles from "./otp.module.scss";
import {useFormHandler} from "../../utils/useFormHandler";
import {useAuth} from "../../context/AuthContext"; // Ensure the path is correct
import {Images} from "../../common/Images";
import styles from "../Login/login.module.scss";
import {verifyOtp} from "../../api/auth/otp";
import {usePermissions} from "../../context/PermissionsContext";
import { resendOtp } from "../../api/auth/resendOtp";

const {Title} = Typography;

interface OtpFormValues {
    otp: string;
  
}

interface OtpPageProps {
    reference_key: string;
  
}

const OtpPage: React.FC<OtpPageProps> = ({reference_key}) => {
    const [form] = Form.useForm<OtpFormValues>();
    const navigate = useNavigate();
    const {login} = useAuth();
    const {fetchPermissions} = usePermissions();
    const [timer, setTimer] = useState<number>(60); 
    const [ResendDisabled, setResendDisabled] = useState<boolean>(true);
    const [reference, setReference] = useState(reference_key)

    const handleSuccess = async (response: any) => {
      
        message.success("OTP verified successfully!");
        // Use the login function from context to handle successful authentication
        login(response.access, response.refresh);
        await fetchPermissions()
        navigate("/dashboard");
       
    };

    const onFinish = useFormHandler(
        async (value) => {
            return verifyOtp({
                otp: value.otp,
                reference_key:reference,
            });
        },
        form,
        handleSuccess
    );

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(countdown); 
        } else {
            setResendDisabled(false); 
        }
    }, [timer]);

    const handleResendSuccess = (response:any) => {
        message.success("OTP resent successfully!");
        setTimer(60);
        setResendDisabled(true);
        setReference(response.reference_key); 
    };


    const onResendOtp = async () => {
        try {
            const response = await resendOtp({ reference_key: reference });
            handleResendSuccess(response);
        } catch (error: any) {
            message.error(error.message || "Failed to resend OTP.");
        }
    };
    

    return (
        <div className={styles.loginForm}>
            <div className={styles["img-logo"]}>
                <img src={Images.logo} alt="title" className={styles["baluja"]}/>
                <Link to={"https://lms-website-pink.vercel.app/"}>Back to website</Link>
            </div>
            <h1 className={styles["signin-title"]}>Sign into Your Account</h1>
            <Title level={3} className={styles["otp-title"]}>
                Enter OTP
            </Title>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                className={styles["otp-form"]}
            >
                <Form.Item name={"otp"} className={styles["form-item"]}>
                    <Input.OTP size={"large"} length={6}/>
                </Form.Item>
                <Form.Item className={styles["submit-button"]}>
                    <Button type="primary" htmlType="submit" size="large" block>
                        Submit
                    </Button>
                </Form.Item>
            </Form>

            <div className={styles["resend-section"]}>
                {ResendDisabled ? (
                    <span className={styles["timer"]}>Resend available in {timer}</span>
                ) : (
                    <Button
                        type="link"
                        onClick={onResendOtp}
                        className={styles["resend-button"]}
                    >
                        Resend OTP
                    </Button>
                )}
            </div>
        </div>
    );
};

export default OtpPage;
