import React, { useEffect, useState } from 'react';
import { Button, Tabs } from 'antd';
import Overview from './Overview';
import Attendance from './Attendance';
import LiveClasses from './LiveClasses';
import { useParams } from 'react-router-dom';
import { BatchProps } from '../../types/batch';
import { purchaseDetailStudentBatch } from "../../api/batch/student/studentBatch";
import InstallmentList from "./InstallmentList"; // Assuming you have a type for batch
import PageLoader from '../../common/page-loader/PageLoader';
import Content from "./Content";
import OfflineClasses from './OfflineClasses';
import AnnouncementsBatch from './AnnouncementsBatch';
import styles from "./studentbatch.module.scss"
import ReviewDrawer from "../../studentComponent/Review/ReviewDrawer"

const { TabPane } = Tabs;


const StudentBatchDetail: React.FC = () => {
    const { batchId } = useParams<{ batchId: string }>(); // Getting batch ID from URL params
    const [batch, setBatch] = useState<BatchProps | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [addReviewModal, setAddReviewModal] = useState<boolean>(false);

    const handleOpenAddDrawer = () => {
        setAddReviewModal(true)
    }

    const handleOpenCloseDrawer = () => {
        setAddReviewModal(false);
    }

    const fetchBatchDetails = async () => {
        if (!batchId) {
            setError('Batch ID is missing');
            setLoading(false);
            return;
        }

        try {
            const batchData: BatchProps = await purchaseDetailStudentBatch(batchId);
            setBatch(batchData);
        } catch (err) {
            setError('Failed to fetch batch details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBatchDetails();
    }, [batchId]);

    if (loading) return <PageLoader />;
    if (error) return <div>{error}</div>;

    if (!batch) return <div>No batch found.</div>;

    return (
        <div style={{ padding: '20px' }}>
            <h3 style={{ marginBottom: 30 }}>{batch.name}</h3>

            <div className={styles.actionBtnDiv}>
                <Button type="primary" onClick={() => handleOpenAddDrawer()}>
                    Submit Review
                </Button>
            </div>

            <Tabs defaultActiveKey="1" tabPosition="left" style={{ width: '100%' }}>
                <TabPane tab="Overview" key="1">
                    <Overview batch={batch} />
                </TabPane>
                <TabPane tab="Content" key="2">
                    <Content batch={batch} />
                </TabPane>
                <TabPane tab="Attendance" key="3">
                    <Attendance batch={batch} />
                </TabPane>
                {/*<TabPane tab="Announcements" key="3">*/}
                {/*    <Announcements batch={batch} />*/}
                {/*</TabPane> *!/*/}
                {/* <TabPane tab="Assignments" key="4">
                    <Assignments batch={batch} />
                </TabPane> */}
                {/*<TabPane tab="Videos" key="5">*/}
                {/*    <Videos batch={batch} />*/}
                {/*</TabPane>*/}
                <TabPane tab="Live Classes" key="6">
                    <LiveClasses batch={batch} />
                </TabPane>

                <TabPane tab="Offline Classes" key="9">
                    <OfflineClasses batch={batch} />
                </TabPane>
                {/*<TabPane tab="Study Material" key="7">*/}
                {/*    <StudyMaterial batch={batch} />*/}
                {/*</TabPane>*/}
                <TabPane tab="Installment" key="8">
                    <InstallmentList batch={batch} />
                </TabPane>

                <TabPane tab="Announcement" key="10">
                    <AnnouncementsBatch batch={batch} />
                </TabPane>
            </Tabs>

            {addReviewModal && (
                <ReviewDrawer
                    open={addReviewModal}
                    onClose={handleOpenCloseDrawer}
                    type="batch"
                    batch={batch}
                />

            )}
        </div>
    );
};

export default StudentBatchDetail;
