import React, {useState} from "react";
import {Button, Checkbox, Form, Input, Layout, message, Space, Typography,} from "antd";
import {FcGoogle} from "react-icons/fc";
import {BsFacebook} from "react-icons/bs";
import styles from "../Login/login.module.scss";
import {useFormHandler} from "../../utils/useFormHandler";
import {handleSignup} from "../../api/auth/signupApi";
import {Images} from "../../common/Images";
import OtpPage from "../OTPVerification/Otp";

const {Title, Text} = Typography;

interface SignupFormValues {
    fullName: string;
    email: string;
    mobile: string;
    termsAccepted: boolean;
}

const SignupPage: React.FC = () => {
    const [form] = Form.useForm<SignupFormValues>();
    const [reference_key, setReferenceKey] = useState(null)

    const onFinish = useFormHandler(handleSignup, form, (response: any) => {
        const {reference_key} = response;
        message.success("Signup successful! Please verify OTP sent to your phone.");
        setReferenceKey(reference_key)
    });
    console.log(process.env.REACT_APP_BASE_URL,'BASE_URL')

    return (
        <Layout className={styles["login-container"]}>
            <div className={styles["login-page"]}>
                <div className="row">
                    <div className="col-sm-6 col-12 px-0">
                        <div className={styles["login-left"]}>
                            <img
                                src={Images.baluja}
                                alt="Welcome illustration"
                                className={styles["illustration"]}
                            />
                            <Title level={3} className={styles["welcome-title"]}>
                                Welcome to Baluja Classes.
                            </Title>
                            <Text className={styles["signup-text"]}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam.
                            </Text>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 px-0">
                        <div className={styles["login-right"]}>
                            {reference_key ?
                                <OtpPage reference_key={reference_key}/>
                                :
                                <div className={styles.loginForm}>
                                    <div className={styles["img-logo"]}>
                                        <img
                                            src={Images.logo}
                                            alt="title"
                                            className={styles["baluja"]}
                                        />
                                    </div>

                                    <Title level={3} className={styles["signin-title"]}>
                                        Sign Up
                                    </Title>
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        onFinish={onFinish}
                                        className={styles["login-form"]}
                                    >
                                        <Form.Item
                                            label="Full Name"
                                            name="full_name"
                                            rules={[
                                                {required: true, message: "Please enter your full name"},
                                            ]}
                                            className={styles["form-item"]}
                                        >
                                            <Input placeholder="Enter your full name" size="large"/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "email",
                                                    message: "Please enter a valid email",
                                                },
                                            ]}
                                            className={styles["form-item"]}
                                        >
                                            <Input placeholder="Enter your email" size="large"/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Phone Number"
                                            name="phone_number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your mobile number",
                                                },
                                            ]}
                                            className={styles["form-item"]}
                                        >
                                            <Input placeholder="Enter your mobile" size="large"/>
                                        </Form.Item>

                                        <Form.Item
                                            name="termsAccepted"
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "You must accept the terms and conditions",
                                                },
                                            ]}
                                        >
                                            <Checkbox>
                                                I agree to the
                                                <a href="/terms" target="_blank" rel="noopener noreferrer">
                                                    {" "}
                                                    Terms of Service{" "}
                                                </a>
                                                and
                                                <a
                                                    href="/privacy"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {" "}
                                                    Privacy Policy
                                                </a>
                                                .
                                            </Checkbox>
                                        </Form.Item>

                                        <Form.Item className={styles["submit-button"]}>
                                            <Button type="primary" htmlType="submit" size="large" block>
                                                Create Account
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>}
                            <div className={styles["social-signin-container"]}>
                                <div className={styles["signin-text"]}>Or sign in with</div>
                                <Space>
                                    <a href="/auth/google" className={styles["signin-link"]}>
                                        <FcGoogle/> Sign In using Google
                                    </a>
                                    <span className={styles["separator"]}>|</span>
                                    <a href="/auth/facebook" className={styles["signin-link"]}>
                                        <BsFacebook color="#1878f2"/> Sign In using Facebook
                                    </a>
                                </Space>
                                <p className={styles["new-user"]}>
                                    Already have an account? <a href="/">Login</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default SignupPage;
