import React from "react";
import styles from "./pageHeader.module.scss"
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { IoSettingsOutline } from "react-icons/io5";
import { ArrowLeftOutlined } from "@ant-design/icons"

interface pageHeaderProps {
    heading: string;
    onConfigClick?: any;


    onBackClick?: any;

}
const PageHeader: React.FC<pageHeaderProps> = ({ heading, onConfigClick, onBackClick }) => {

    return (
        <div className={styles.pageHeader}>


            {onBackClick && (
                <ArrowLeftOutlined onClick={onBackClick} style={{ marginRight: "8px" }} />
            )}


            <h3> {heading}</h3>

            {onConfigClick && <div onClick={onConfigClick} className={styles.headerIcon}><IoSettingsOutline /> Configurations</div>}
        </div>
    )
}
export default PageHeader;