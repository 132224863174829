// CourseDetail.tsx
import React, { useEffect, useState } from 'react';
import { Button, Tabs } from 'antd';
import Overview from './Overview';
import Content from './Content';
import LiveClasses from './LiveClasses';
import Attendance from './Attendance';
import { useParams } from "react-router-dom";
import { purchaseDetailStudentCourse } from "../../api/course/student/studentCourse";
import { CourseProps } from "../../types/course";
import PageHeader from '../../common/page-header/PageHeader';
import styles from "../Course/coursedata.module.scss"
import AnnouncementsCourse from './AnnouncementsCourse';
import ReviewDrawer from '../../studentComponent/Review/ReviewDrawer'

const { TabPane } = Tabs;

const CourseDetail: React.FC = () => {
    const { courseId } = useParams<any>();
    const [course, setCourse] = useState<CourseProps | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [addReviewModal, setAddReviewModal] = useState<boolean>(false);

    const handleOpenAddDrawer = () => {
        setAddReviewModal(true)
    }

    const handleOpenCloseDrawer = () => {
        setAddReviewModal(false);
    }

    const fetchCourseDetails = async () => {
        if (!courseId) {
            setError('Course ID is missing');
            setLoading(false);
            return;
        }

        try {
            const courseData: CourseProps = await purchaseDetailStudentCourse(courseId);
            setCourse(courseData);
        } catch (err) {
            setError('Failed to fetch course details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCourseDetails();
    }, [courseId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    if (!course) return <div>No course found.</div>;

    return (
        <div className={styles.courseData}>

            <PageHeader heading={course.name} />

            <div className={styles.actionBtnDiv}>
                <Button type="primary" onClick={() => handleOpenAddDrawer()}>
                    Submit Review
                </Button>
            </div>
            <Tabs defaultActiveKey="1" tabPosition="left" style={{ width: '100%' }}>
                <TabPane tab="Overview" key="1">
                    <Overview course={course} />
                </TabPane>
                <TabPane tab="Content" key="2">
                    <Content course={course} />
                </TabPane>
                <TabPane tab="Live Classes" key="3">
                    <LiveClasses course={course} />
                </TabPane>
                {/*<TabPane tab="Attendance" key="4">*/}
                {/*    <Attendance course={course}/>*/}
                {/*</TabPane>*/}
                <TabPane tab="Announcements" key="5">
                    <AnnouncementsCourse course={course} />
                </TabPane>
            </Tabs>
            {addReviewModal && (
                <ReviewDrawer
                    open={addReviewModal}
                    onClose={handleOpenCloseDrawer}
                    type="course"
                    course={course}
                />
            )}
        </div>
    );
};

export default CourseDetail;
