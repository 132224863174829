// App.tsx
import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {ConfigProvider, Spin} from 'antd';
import StudentDashboardHome from './studentPages/DashboardHome/DashboardHome';

import {useAuth} from './context/AuthContext';

import DashboardHome from './pages/DashboardHome/DashboardHome';
import CoursesList from './pages/Courses/CoursesList';

import StudentsList from './pages/Students/students-list/StudentsList';
import BatchesList from './pages/Batches/BatchesList';
import Settings from './pages/Settings';
import LeadsTable from './pages/Leads';
import UsersList from './pages/Users/users-list/UsersList';
import WebsiteMenus from './pages/WebsiteMenus';
import TestPortal from './pages/TestPortal';
import Reports from './pages/reports/Reports';
import CourseCategories from './pages/CourseCategories/CourseCategories';


import NewCourse from './studentComponent/NewCourse';
import LoginPage from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import PublicRoute from "./common/PublicRoute";
import PrivateRoute from "./common/PrivateRoute";
import Layout from "./common/layout/Layout";
import BatchSubjects from "./pages/Batches/BatchSubjects";
// import ManageFee from './pages/ManageFee/ManageFee';
import TestSeries from './pages/TestSeries/TestSeries';
import FreeResources from './pages/FreeResources/FreeResources';
import Coupons from "./pages/Coupons/Coupons";
import BatchDetail from "./pages/Batches/BatchDetail";
import StudentBatchDetail from "./studentComponent/Batch/StudentBatchDetail";
import CourseDetail from "./studentComponent/Course/CourseDetail";
import StudentCoursesList from "./studentPages/StudentCoursesList";
import StudentBatchesList from "./studentPages/StudentBatchesList";
import Profile from './pages/Profile/Profile';
import TestSeriesCategories from "./pages/TestSeries/TestSeriesCategories";
import StudentProductList from "./studentPages/StudentProductList";
import StudentFreeResourcesList from "./studentPages/StudentFreeResourcesList";
import FeeStructure from './components/fee-structure/FeeStructure';
import FeeRecords from './components/fee-records/FeeRecords';
import {ModuleName, UserRole} from "./utils/Role";
import ProtectedRoute from "./common/ProtectedRoute";
import StudentTransactionsTable from "./studentPages/StudentTransaction";
import TransactionsTable from "./pages/Transactions/Transactions";
import StudentFeeRecords from "./studentPages/StudentFeesRecords";
import AnnouncementList from "./studentPages/AnnouncementList";
import FreeResourceDetail from "./studentComponent/FreeResource/FreeResourceDetail";
import CourseDetailPage from './pages/CourseDetailPage/CourseDetailPage';
import LiveViewIFrame from "./components/LiveViewIFrame";


const App: React.FC = () => {
    const {user, loading} = useAuth();
    const userRole = user?.userRole;

    if (loading) {
        return (<div className="spinner">
            <Spin size="large"/>
        </div>);
    }
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#02b3e4',
                },
            }}
        >
            <Router>
                <Routes>
                    {/* Public routes */}
                    <Route element={<PublicRoute/>}>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/signup" element={<Signup/>}/>
                        <Route path="*" element={<Navigate to="/login"/>}/>
                    </Route>

                    {/* Private routes */}
                    <Route element={<PrivateRoute/>}>
                        <Route element={<Layout/>}>
                            {userRole !== UserRole.STUDENT && (
                                <>
                                    <Route path="/" element={<DashboardHome/>}/>

                                    {/* Use the ModuleName enum for each module */}
                                    <Route
                                        element={<ProtectedRoute moduleName={ModuleName.COURSES} action="view"/>}>
                                        <Route path="/courses" element={<CoursesList/>}/>
                                    </Route>

                                    <Route
                                        element={<ProtectedRoute moduleName={ModuleName.STUDENTS} action="view"/>}>
                                        <Route path="/students" element={<StudentsList/>}/>
                                    </Route>

                                    <Route element={<ProtectedRoute moduleName={ModuleName.FEE_RECORD}
                                                                    action="view"/>}>
                                        <Route path="/fee-record" element={<FeeRecords/>}/>
                                    </Route>

                                    <Route element={<ProtectedRoute moduleName={ModuleName.FEE_STRUCTURE}
                                                                    action="view"/>}>
                                        <Route path="/fee-structure" element={<FeeStructure/>}/>
                                    </Route>

                                    <Route
                                        element={<ProtectedRoute moduleName={ModuleName.BATCHES} action="view"/>}>
                                        <Route path="/batches" element={<BatchesList/>}/>
                                    </Route>

                                    <Route
                                        element={<ProtectedRoute moduleName={ModuleName.COUPONS} action="view"/>}>
                                        <Route path="/coupons" element={<Coupons/>}/>
                                    </Route>

                                    <Route element={<ProtectedRoute moduleName={ModuleName.TEST_PORTAL}
                                                                    action="view"/>}>
                                        <Route path="/quiz" element={<TestPortal/>}/>
                                    </Route>

                                    <Route element={<ProtectedRoute moduleName={ModuleName.LEAD_INQUIRIES}
                                                                    action="view"/>}>
                                        <Route path="/leads" element={<LeadsTable/>}/>
                                    </Route>

                                    <Route element={<ProtectedRoute moduleName={ModuleName.TEST_SERIES}
                                                                    action="view"/>}>
                                        <Route path="/products" element={<TestSeries/>}/>
                                    </Route>

                                    <Route element={<ProtectedRoute moduleName={ModuleName.TRANSACTIONS}
                                                                    action="view"/>}>
                                        <Route path="/transactions" element={<TransactionsTable/>}/>
                                    </Route>

                                    <Route element={<ProtectedRoute moduleName={ModuleName.FREE_RESOURCE}
                                                                    action="view"/>}>
                                        <Route path="/free-resource" element={<FreeResources/>}/>
                                    </Route>

                                    <Route element={<ProtectedRoute moduleName={ModuleName.TEAM_MEMBERS}
                                                                    action="view"/>}>
                                        <Route path="/users" element={<UsersList/>}/>
                                    </Route>

                                    <Route element={<ProtectedRoute moduleName={ModuleName.REPORT} action="view"/>}>
                                        <Route path="/reports" element={<Reports/>}/>
                                    </Route>

                                    <Route
                                        element={<ProtectedRoute moduleName={ModuleName.SETTINGS} action="view"/>}>
                                        <Route path="/settings" element={<Settings/>}/>
                                    </Route>
                                    git statusgit <Route path="/website-menus" element={<WebsiteMenus/>}/>
                                    {/* Inner Route components */}
                                    <Route path="/batches/:batchId" element={<BatchDetail/>}/>
                                    <Route path="/course/:courseId" element={<CourseDetailPage/>}/>
                                    <Route path="/course-categories" element={<CourseCategories/>}/>
                                    <Route path="/batch-subjects" element={<BatchSubjects/>}/>
                                    {/* <Route path="/manage-fee" element={<ManageFee/>}/> */}
                                    <Route path="/test-series/configration" element={<TestSeriesCategories/>}/>
                                    <Route path="/chat" element={<DashboardHome/>}/>
                                </>)}

                            {/* Student Routes */}
                            {userRole === UserRole.STUDENT && (
                                <>
                                    <Route path="/" element={<StudentDashboardHome/>}/>
                                    <Route path="/student-courses" element={<StudentCoursesList/>}/>
                                    <Route path="/student-batches" element={<StudentBatchesList/>}/>
                                    <Route path="/digital-products" element={<StudentProductList is_digital={true}/>}/>
                                    <Route path="/physical-products"
                                           element={<StudentProductList is_digital={false}/>}/>
                                    <Route path="/student-transactions" element={<StudentTransactionsTable/>}/>
                                    <Route path="/student-fee-record" element={<StudentFeeRecords/>}/>
                                    <Route path="/student-free-resources" element={<StudentFreeResourcesList/>}/>
                                    <Route path="/purchased-course/:courseId" element={<CourseDetail/>}/>
                                    <Route path="/new-course/:courseId" element={<NewCourse/>}/>
                                    <Route path="/purchased-batch/:batchId" element={<StudentBatchDetail/>}/>
                                    <Route path="/student-free-resources/:resourceId" element={<FreeResourceDetail/>}/>
                                    <Route path="/announcements" element={<AnnouncementList/>}/>
                                    {/*<Route path="/new-batch/:batchId" element={<NewBatch/>}/>*/}
                                </>
                            )}
                            <Route path="/profile" element={<Profile/>}/>
                        </Route>
                    </Route>
                    <Route path='/full-screen' element={<LiveViewIFrame/>}> </Route>
                </Routes>
            </Router>
            <div className='copyright'>
                <p>© Baluja Classes. All rights reserved | Design and Developed by <a target='_blank' href="https://orbitinfotech.com">Orbit Infotech</a></p>
            </div>
        </ConfigProvider>
    );
};

export default App;
