import StatsCard from "../../common/StatsCard/StatsCard";
import styles from "./dashboardHome.module.scss";
import cn from "classnames";
import {FcGraduationCap, FcReading} from "react-icons/fc";
import {GiBookshelf} from "react-icons/gi";
import ImageCard from "../../common/imageCard/ImageCard";
import PageHeader from "../../common/page-header/PageHeader";
import {Button, Dropdown, Table, Tag} from "antd";
import {useEffect, useState} from "react";
import CreateCourse from "../../components/create-course-drawer/CreateCourseDrawer";
import {MdOutlineCurrencyRupee} from "react-icons/md";

import {Images} from "../../common/Images";
import {getGlobalMetrics, GlobalMetrics} from "../../api/widgets/widgets";
import {BASE_URL} from "../../utils/apiClient";
import { FiBook } from "react-icons/fi";
import {
    CloudUploadOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    MoreOutlined,
    PlusCircleTwoTone,
    StopOutlined,
    UserAddOutlined,
    UsergroupAddOutlined,
} from "@ant-design/icons";
import PageLoader from "../../common/page-loader/PageLoader";
import { useAuth } from "../../context/AuthContext";
import CoursePrice from "../Courses/CoursePricing";
import { useNavigate } from "react-router-dom";

const DashboardHome = () => {
    const { user } = useAuth();  
    const [openCreateCourse, setOpenCreateCourse] = useState(false)
    const [globalMetrics, setGlobalMetrics] = useState<GlobalMetrics | null>(null);
    const [loading,setLoading]=useState<boolean>(false)
    const navigate =useNavigate()

    const fetchGlobalMetrics = async () => {
        try {
            setLoading(true)
            const metrics = await getGlobalMetrics();
            setGlobalMetrics(metrics);
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.error('Error fetching student metrics:', err);
        } finally {
        }
    };

    useEffect(() => {
        fetchGlobalMetrics()
    }, [])
    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading={`Welcome back, ${user?.full_name || "Guest"}!`} />
          {loading &&  <PageLoader />}
            <section>
                <div className="row">
                    {globalMetrics ? (
                        <>
                            <div className="col-12 col-sm-3">
                                <StatsCard
                                    icon={<FcReading color="#9E49E6" className="icon"/>}
                                    iconBg="rgb(231, 244, 251)"
                                    title={globalMetrics.total_students.toString()}
                                    description={"Total Student"}
                                />
                            </div>
                            <div className="col-12 col-sm-3">
                                <StatsCard
                                    icon={<GiBookshelf color="#49BD65" className="icon"/>}
                                    iconBg="rgb(237, 248, 240)"
                                    title={globalMetrics.total_courses.toString()}
                                    description={"Total Courses"}
                                />
                            </div>
                            <div className="col-12 col-sm-3">
                                <StatsCard
                                    icon={<FcGraduationCap color="#0A8FDC" className="icon"/>}
                                    iconBg="rgb(231, 244, 251)"
                                    title={globalMetrics.total_batches.toString()}
                                    description={"Total Batches"}
                                />
                            </div>
                            <div className="col-12 col-sm-3">
                                <StatsCard
                                    icon={<MdOutlineCurrencyRupee color="#9E49E6" className="icon"/>}
                                    iconBg="rgb(245, 237, 252)"
                                    title={globalMetrics.total_instructor.toString()}
                                    description={"Total Instructor"}
                                />
                            </div>
                        </>
                    ) : (
                        <div>Loading metrics...</div>
                    )}
                </div>
            </section>
            <section className={styles.coursesSection}>
                <div className="row">
                    <div className="col-12">
                        <h4 className={styles.sectionHeading}>Top Trending Courses</h4>
                    </div>
                    {globalMetrics?.trending_courses.map((course: any, index: number) => {
                        return <div className="col-sm-4 col-12" key={index}>
                            <ImageCard
                                className={styles.courseCard}
                                title={course.name}
                                description={course.description}
                                image={
                                    `${BASE_URL}${course.thumbnail}`
                                }
                            >
                                <div className={styles.tagsDiv}>
                                    <p className={styles.durationCourse}>
                                        {course.validity_type === 'single' && (
                                            <>
                                                Duration: <span>{course.duration_value} {course.duration_unit}</span>
                                            </>
                                        )}
                                        {course.validity_type === 'lifetime' && (
                                            <>
                                                Duration: <span>Lifetime Access</span>
                                            </>
                                        )}
                                        {course.validity_type === 'expiry_date' && course.expiry_date && (
                                            <>
                                                Expiry
                                                Date: <span>{new Date(course.expiry_date).toLocaleDateString()}</span>
                                            </>
                                        )}
                                    </p>
                                   {course.validity_type==="multiple"? 
                                    <div className={styles.courseMuliPrice}>
                                        <CoursePrice className={styles.multiPriceDiv} course={course}/>
                                    </div>
                                    :
                                    <div className={styles.priceCourse}>
                                       <div>
                                        {course.effective_price ? <span>₹{course.effective_price}</span> :
                                        <i>Not Added</i>}
                                        {course.price && <div className={styles.discountedPrice}>₹{course.price}</div>} 
                                       </div>                                    
                                    </div>
                                    }
                                </div>
                                <p className={styles.createdByTag}>
                                    Created By: <span>{course.created_by}</span>
                                </p>
                            </ImageCard>
                        </div>
                    })}

                </div>
            </section>

            <section className={styles.batchesSection}>
            <div className={cn(styles.batchRow, "row")}>
                    <div className="col-12">
                        <h4 className={styles.batchesHeading}>Top Trending Batches</h4>
                    </div>
                    {globalMetrics?.trending_batches.map((batch: any) => (
                        <div className="col-sm-4 col-12" key={batch.id}>
                            <div className={styles.batchDiv}>
                                <div onClick={()=>navigate(`/batches/${batch.id}`)} className={styles.batchImg}>
                                    <img src={`${BASE_URL}${batch.thumbnail}`} alt={batch.name} />
                                </div>

                                <div className={styles.batchContent}>
                                    <div onClick={()=>navigate(`/batches/${batch.id}`)} className={styles.heading}>
                                        {batch.name.toUpperCase()}
                                    </div>
                                </div>
                                <div className={styles.footer}>
                                    <div className={styles.subjectName}><FiBook /> {batch.subject.name}</div>
                                </div>
                            </div>
                         </div>
                    ))}

                </div>
            </section>


            <CreateCourse
                open={openCreateCourse}
                course={null}
                onClose={() => setOpenCreateCourse(false)}
            />
        </div>
    );
};
export default DashboardHome;
