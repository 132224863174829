// src/components/Layout.tsx
import React from 'react';
import styles from './layout.module.scss';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import {Outlet} from 'react-router-dom';
import {UserRoleProps} from "../../context/AuthContext";


const Layout: React.FC = () => {

    return (
        <>
            <Header/> {/* Pass the role to the Header */}
            <div className={styles.layoutContainer}>
                {/* Conditionally render Sidebar only for admin */}
                <Sidebar/>
                <main className={styles.mainContent}>
                    <Outlet/> {/* Renders the nested routes */}
                </main>
            </div>
        </>
    );
};

export default Layout;
