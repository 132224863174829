import React, {useEffect, useState} from 'react'
import PageHeader from '../../common/page-header/PageHeader'
import {Tabs, TabsProps} from 'antd'
import styles from "./profile.module.scss"
import PersonalDetailsTab from './PersonalDetailsTab';
import {useAuth} from '../../context/AuthContext';

import {getMyProfile} from "../../api/profile/profile";
import AddressDetailsTab from "../../studentComponent/Profile/AddressDetailsTab";
import EducationDetails from "../../studentComponent/Profile/EducationDetails";
import ParentsDetailsTab from "../../studentComponent/Profile/ParentsDetailsTab";
import BasicPersonalDetailsTab from "../../studentComponent/Profile/BasicPersonalDeatil";


export default function Profile() {
    const [profile, setProfile] = useState<any>(null);

    const {user} = useAuth();


    const studentTabs = [

        {
            key: '5',
            label: 'Basic Detail',
            children: <BasicPersonalDetailsTab profile={profile} onClose={() => {
            }}/>,
        },
        {
            key: '2',
            label: 'Address',
            children: <AddressDetailsTab profile={profile} onClose={() => {
            }}/>,
        },
        {
            key: '3',
            label: 'Education',
            children: <EducationDetails profile={profile} onClose={() => {
            }}/>,
        },
        {
            key: '4',
            label: 'Parent Info',
            children: <ParentsDetailsTab profile={profile} onClose={() => {
            }}/>,
        },


    ]
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Personal Details',
            children: <PersonalDetailsTab profile={profile}/>,
        },
        ...(user?.userRole === "STUDENT" ? studentTabs : [])
    ];

    const getProfileData = async () => {
        const data: any = await getMyProfile()
        setProfile(data)
    }
    useEffect(() => {
        if (user?.userRole == "STUDENT") {
            getProfileData()
        }

    }, [])


    return (
        <>
            <PageHeader heading={"Profile"}/>
            <div className={styles.profileTabs}>
                <Tabs
                    items={items}
                    tabPosition="left"
                    // style={{ width: '50%' }}
                    defaultActiveKey="1"
                />
            </div>
        </>
    )
}

// const ChangePasswordTab: React.FC = () => (
//     <Form style={{width:"60%"}} layout="vertical">
//         <Form.Item label="Current Password" name="current_pass">
//             <Input placeholder="Enter Current Password" />
//         </Form.Item>
//         <Form.Item label="New Password" name="new_pass">
//             <Input placeholder="Enter New Password" />
//         </Form.Item>
//         <Form.Item label="Confirm Password" name="confirm_pass">
//             <Input placeholder="Enter Confirm Password" />
//         </Form.Item>
//         <Form.Item>
//             <Button type="primary">Save</Button>
//         </Form.Item>
//     </Form>
// );
