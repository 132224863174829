import React, {useEffect, useState} from "react";
import {CgWebsite} from "react-icons/cg";
import {Button, Form, Input, InputNumber, message, Switch, Tabs} from "antd";
import styles from "./settings.module.scss";
import PageHeader from "../../common/page-header/PageHeader";
import WebsiteMenus from "../WebsiteMenus";
import {getSettings, updateSettings} from "../../api/settings/settingApi";
import {showErrors} from "../../utils/useFormHandler";
import {SettingsDataProps} from "../../types/settings";
import {SettingsKeys} from "./keys";
import ImageUpload from "../../components/uploadField";
import {CiBank, CiCreditCard1} from "react-icons/ci";
import {BsInfoCircle, BsPercent} from "react-icons/bs";
import {HiOutlineMail} from "react-icons/hi";
import {FaMobileScreen} from "react-icons/fa6";
import {MdOutlineDisplaySettings, MdOutlineSecurity} from "react-icons/md";
import {AiOutlineGlobal, AiOutlineMessage} from "react-icons/ai";

const {TabPane} = Tabs;

const Settings: React.FC = () => {
    const [settingsData, setSettingsData] = useState<SettingsDataProps | null>(
        null
    );
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data = await getSettings();
                setSettingsData(data);
            } catch (error) {
                message.error("Failed to load settings.");
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const handleUpdate = async (updatedData: SettingsDataProps) => {
        try {
            const updatedSettings = await updateSettings(updatedData);
            message.success("Settings updated successfully");
        } catch (error) {
            showErrors(error);
        }
    };

    if (loading) return <div>Loading...</div>;
    return (
        <>
            <PageHeader heading={"Settings"}/>
            <div className={styles.settingTabs}>
                <Tabs tabPosition="left" defaultActiveKey="0">
                    <TabPane
                        tab={
                            <>
                                <BsPercent/> Taxation
                            </>
                        }
                        key="0"
                    >
                        <TaxationConfigForm/>
                    </TabPane>

                    <TabPane
                        tab={
                            <>
                                <CiCreditCard1/> Razorpay Gateway
                            </>
                        }
                        key="razor"
                    >
                        <PaymentGatewayConfigForm data={settingsData!} onUpdate={handleUpdate}/>
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <CiCreditCard1/> MeritHub API
                            </>
                        }
                        key="merithub"
                    >
                        <MeritHubConfigForm data={settingsData!} onUpdate={handleUpdate}/>
                    </TabPane>

                    <TabPane
                        tab={
                            <>
                                <HiOutlineMail/> Email Config
                            </>
                        }
                        key="6"
                    >
                        <EmailConfigForm/>
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <MdOutlineDisplaySettings/> Website Config
                            </>
                        }
                        key="1"
                    >
                        <WebsiteConfigForm/>
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <FaMobileScreen/> Mobile App Config
                            </>
                        }
                        key="2"
                    >
                        <MobileAppConfigForm/>
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <AiOutlineGlobal/> Global Settings
                            </>
                        }
                        key="3"
                    >
                        <GlobalSettingsForm/>
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <MdOutlineSecurity/> Security
                            </>
                        }
                        key="4"
                    >
                        <SecuritySettingsForm/>
                    </TabPane>

                    <TabPane
                        tab={
                            <>
                                <AiOutlineMessage/> Message Config
                            </>
                        }
                        key="7"
                    >
                        <MessageConfigForm/>
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <CgWebsite/> Website Menu
                            </>
                        }
                        key="8"
                    >
                        <WebsiteMenus/>
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <CiBank/> Bank Details
                            </>
                        }
                        key="9"
                    >
                        <BankDetails data={settingsData!} onUpdate={handleUpdate}/>
                    </TabPane>

                    <TabPane
                        tab={
                            <>
                                <BsInfoCircle/> Company Information
                            </>
                        }
                        key="10"
                    >
                        <CompanyInformation data={settingsData!} onUpdate={handleUpdate}/>
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <CiCreditCard1/> Invoice Settings
                            </>
                        }
                        key="11"
                    >
                        <InvoiceConfigForm data={settingsData!} onUpdate={handleUpdate}/>
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
};

const WebsiteConfigForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">
            <Form layout="vertical">
                <Form.Item label="Website Name" name="websiteName">
                    <Input placeholder="Enter website name"/>
                </Form.Item>
                <Form.Item label="Website URL" name="websiteUrl">
                    <Input placeholder="Enter website URL"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save Website Config</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);

const TaxationConfigForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">
            <Form layout="vertical">
                <Form.Item label="Tax Percentage" name="percentage">
                    <InputNumber min={0} placeholder="Enter Tax Percentage"/>
                </Form.Item>
                <Form.Item label="Internet Charges" name="internet_charges">
                    <InputNumber min={0} placeholder="Enter Internet Charges"/>
                </Form.Item>
                <Form.Item label="Platform Fee" name="platform_fee">
                    <InputNumber min={0} placeholder="Enter Platform Fee"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);
const MobileAppConfigForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">

            <Form layout="vertical">
                <Form.Item label="App Name" name="appName">
                    <Input placeholder="Enter mobile app name"/>
                </Form.Item>
                <Form.Item label="App Version" name="appVersion">
                    <Input placeholder="Enter app version"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save Mobile App Config</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);

const GlobalSettingsForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">
            <Form layout="vertical">
                <Form.Item
                    label="Enable Maintenance Mode"
                    name="maintenanceMode"
                    valuePropName="checked"
                >
                    <Switch/>
                </Form.Item>
                <Form.Item label="Site Locale" name="locale">
                    <Input placeholder="Enter locale (e.g. en-US)"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save Global Settings</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);

const SecuritySettingsForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">

            <Form layout="vertical">
                <Form.Item label="Enable 2FA" name="twoFactorAuth" valuePropName="checked">
                    <Switch/>
                </Form.Item>
                <Form.Item
                    label="Password Reset Token Expiry (in minutes)"
                    name="tokenExpiry"
                >
                    <Input type="number" placeholder="Enter token expiry time"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save Security Settings</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);

const PaymentGatewayConfigForm: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({ data, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.RAZORPAY_API_KEY]: data[SettingsKeys.RAZORPAY_API_KEY],
            [SettingsKeys.RAZORPAY_API_SECRET]: data[SettingsKeys.RAZORPAY_API_SECRET],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
                label="Razorpay API Key"
                name={SettingsKeys.RAZORPAY_API_KEY}
                rules={[{ required: true, message: 'API Key is required' }]}
            >
                <Input placeholder="Enter Razorpay API Key" />
            </Form.Item>
            <Form.Item
                label="Razorpay API Secret"
                name={SettingsKeys.RAZORPAY_API_SECRET}
                rules={[{ required: true, message: 'API Secret is required' }]}
            >
                <Input.Password placeholder="Enter Razorpay API Secret" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Save Razorpay Config
                </Button>
            </Form.Item>
        </Form>
    );
};


const EmailConfigForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">

            <Form layout="vertical">
                <Form.Item label="SMTP Server" name="smtpServer">
                    <Input placeholder="Enter SMTP server"/>
                </Form.Item>
                <Form.Item label="Port" name="port">
                    <Input type="number" placeholder="Enter SMTP port"/>
                </Form.Item>

                <Form.Item label="UserName" name="userName">
                    <Input placeholder="Enter UserName"/>
                </Form.Item>
                <Form.Item label="Password" name="password">
                    <Input placeholder="Enter Password"/>
                </Form.Item>
                <Form.Item label="Sender Email" name="senderEmail">
                    <Input placeholder="Enter Sender Email"/>
                </Form.Item>
                <Form.Item label="Sender Name" name="senderName">
                    <Input placeholder="Enter Sender Name"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save Email Config</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);

const MessageConfigForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">
            <Form layout="vertical">
                <Form.Item label="SMS Provider" name="smsProvider">
                    <Input placeholder="Enter SMS provider"/>
                </Form.Item>
                <Form.Item label="API Token" name="apiToken">
                    <Input placeholder="Enter API token"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save Message Config</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);
const BankDetails: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({data, onUpdate}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.ACCOUNT_NAME]: data[SettingsKeys.ACCOUNT_NAME],
            [SettingsKeys.ACCOUNT_NUMBER]: data[SettingsKeys.ACCOUNT_NUMBER],
            [SettingsKeys.IFSC]: data[SettingsKeys.IFSC],
            [SettingsKeys.SWIFT_CODE]: data[SettingsKeys.SWIFT_CODE],
            [SettingsKeys.BANK]: data[SettingsKeys.BANK],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <div className="row">
            <div className="col-sm-6">
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Form.Item label="Account Name" name={SettingsKeys.ACCOUNT_NAME}>
                        <Input placeholder="Enter Account Name"/>
                    </Form.Item>
                    <Form.Item label="Account Number" name={SettingsKeys.ACCOUNT_NUMBER}>
                        <Input placeholder="Enter Account Number"/>
                    </Form.Item>
                    <Form.Item label="IFSC Code" name={SettingsKeys.IFSC}>
                        <Input placeholder="Enter IFSC Code"/>
                    </Form.Item>
                    <Form.Item label="Swift Code" name={SettingsKeys.SWIFT_CODE}>
                        <Input placeholder="Enter Swift Code"/>
                    </Form.Item>
                    <Form.Item label="Bank Name" name={SettingsKeys.BANK}>
                        <Input placeholder="Enter Bank Name"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save Bank Details
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

const CompanyInformation: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({data, onUpdate}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.BILLED_BY]: data[SettingsKeys.BILLED_BY],
            [SettingsKeys.GSTIN]: data[SettingsKeys.GSTIN],
            [SettingsKeys.COMPANY_ADDRESS]: data[SettingsKeys.COMPANY_ADDRESS],
            [SettingsKeys.PAN]: data[SettingsKeys.PAN],
            [SettingsKeys.EMAIL]: data[SettingsKeys.EMAIL],
            [SettingsKeys.PHONE]: data[SettingsKeys.PHONE],
            [SettingsKeys.ADDRESS]: data[SettingsKeys.ADDRESS],
            [SettingsKeys.PINCODE]: data[SettingsKeys.PINCODE],
            [SettingsKeys.CITY]: data[SettingsKeys.CITY],
            [SettingsKeys.STATE]: data[SettingsKeys.STATE],
            [SettingsKeys.COMPANY_LOGO]: data[SettingsKeys.COMPANY_LOGO],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            className={styles.formContainer}
        >
            <div className={styles.formLeft}>
                <Form.Item label="Bussiness Name" name={SettingsKeys.BILLED_BY}>
                    <Input placeholder="Enter Bussiness Name"/>
                </Form.Item>
                <Form.Item label="GSTIN" name={SettingsKeys.GSTIN}>
                    <Input placeholder="Enter GSTIN Number"/>
                </Form.Item>
                {/* <Form.Item label="COMPANY ADDRESS" name={SettingsKeys.COMPANY_ADDRESS}>
                    <Input placeholder="Enter COMPANY Address"/>
                </Form.Item> */}
                <Form.Item label="PAN" name={SettingsKeys.PAN}>
                    <Input placeholder="Enter PAN"/>
                </Form.Item>

                <Form.Item label="Email" name={SettingsKeys.EMAIL}>
                    <Input placeholder="Enter Email"/>
                </Form.Item>
                <Form.Item label="Phone" name={SettingsKeys.PHONE}>
                    <Input placeholder="Enter Phone"/>
                </Form.Item>
                <div className={styles.submitButton}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save Company Information
                        </Button>
                    </Form.Item>
                </div>
            </div>

            <div className={styles.formRight}>
                <Form.Item label="Address" name={SettingsKeys.ADDRESS}>
                    <Input placeholder="Enter Adresss"/>
                </Form.Item>

                <Form.Item label="Pincode" name={SettingsKeys.PINCODE}>
                    <Input placeholder="Enter PinCode"/>
                </Form.Item>

                <Form.Item label="City" name={SettingsKeys.CITY}>
                    <Input placeholder="Enter City"/>
                </Form.Item>

                <Form.Item label="State" name={SettingsKeys.STATE}>
                    <Input placeholder="Enter State"/>
                </Form.Item>
                <ImageUpload showInstruction={true} required={false} form={form} name={SettingsKeys.COMPANY_LOGO}
                             title={"Company Logo"}/>

            </div>
        </Form>
    );
};
const MeritHubConfigForm: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({ data, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.MERITHUB_CLIENT_ID]: data[SettingsKeys.MERITHUB_CLIENT_ID],
            [SettingsKeys.MERITHUB_CLIENT_SECRET]: data[SettingsKeys.MERITHUB_CLIENT_SECRET],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
                label="MeritHub Client ID"
                name={SettingsKeys.MERITHUB_CLIENT_ID}
                rules={[{ required: true, message: 'Client ID is required' }]}
            >
                <Input placeholder="Enter MeritHub Client ID" />
            </Form.Item>
            <Form.Item
                label="MeritHub Client Secret"
                name={SettingsKeys.MERITHUB_CLIENT_SECRET}
                rules={[{ required: true, message: 'Client Secret is required' }]}
            >
                <Input.Password placeholder="Enter MeritHub Client Secret" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Save MeritHub Config
                </Button>
            </Form.Item>
        </Form>
    );
};

const InvoiceConfigForm: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({data, onUpdate}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.INVOICE_PRE_FIX]: data[SettingsKeys.INVOICE_PRE_FIX],
            [SettingsKeys.INVOICE_NUMBER_COUNTER]: data[SettingsKeys.INVOICE_NUMBER_COUNTER],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <div className="row">
            <div className="col-sm-6">
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Form.Item label="Invoice Prefix" name={SettingsKeys.INVOICE_PRE_FIX}>
                        <Input placeholder="Enter Invoice Prefix"/>
                    </Form.Item>
                    <Form.Item
                        label="Invoice Number Counter"
                        name={SettingsKeys.INVOICE_NUMBER_COUNTER}
                    >
                        <InputNumber min={0} placeholder="Enter Invoice Number Counter"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save Invoice Settings
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Settings;
