import useFetchList from "../../utils/listClient";
import apiClient from "../../utils/apiClient";
import {FileResponse, FolderResponse, GetBatchFolderStructureResponse} from "../../types/folderFiles";

export interface SubjectProps {
    id: number,
    name: string
}


export interface BatchProps {
    id: number;
    name: string;
    batch_code: string;
    start_date: string;
    subject: SubjectProps;
    live_class_link: boolean;
    created_by: string;
    student_join_request: any;
    enrolled_students: any;
    total_enrolled_students: number
}

export interface ApiBatchResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: BatchProps[];
}


// Define the request data type for creating a user
export interface BatchRequestData {
    batch_name: string;
    batch_code: string;
    start_date: string;
    add_subject: string;
}

// Define the response type
interface BatchResponse {
    message: string;
    batch_id: number;  // Example of response data (adjust based on your backend)
}

// API call to create a batch
export const createBatch = async (formData: BatchRequestData): Promise<BatchResponse> => {
    const response = await apiClient.post<BatchResponse>('/api/batch/batches/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};

// Define the request data type for updating a subject
interface UpdateBatchRequestData {
    name?: string;
    description?: string;
    is_active?: boolean;
}

// Define the response type for subject update
interface UpdateBatchResponse {
    message: string;
    batch_id: number;  // Example of response data (adjust based on your backend)
}

// Define the updateSubject function
export const updateBatch = async (batchId: number, values: UpdateBatchRequestData): Promise<UpdateBatchRequestData> => {
    const response = await apiClient.patch<UpdateBatchRequestData>(`/api/batch/batches/${batchId}/`, values);
    return response.data;
};

export const detailBatch = async (batchId: string): Promise<UpdateBatchRequestData> => {
    const response = await apiClient.get<UpdateBatchRequestData>(`/api/batch/batches/${batchId}/`);
    return response.data;
};

// Define the updateSubject function
export const deleteBatch = async (batchId: number) => {
    const response = await apiClient.delete(`/api/batch/batches/${batchId}/`);
    return response.data;
};


export const useBatchList = () => {
    return useFetchList<BatchProps>("/api/batch/batches/");
};

export const createLiveClassForBatch = async (values: any) => {
    const response = await apiClient.post(`/api/batch/create-live-class/`, values);
    return response.data;
}
export const getFeesRecords = async () => {
    const response = await apiClient.get(`/api/batch/fees-record/`);
    return response.data;
};


export const getStudentFeesRecords = async () => {
    const response = await apiClient.get(`/api/batch/student-fees-record/`);
    return response.data;
};


export const deleteLiveClassForBatch = async (classId: number) => {
    const response = await apiClient.delete(`/api/batch/live-classes/${classId}/`);
    return response.data;
}

export const approveStudentEnroll = async (enrollmentId: number) => {
    const response = await apiClient.post(`/api/batch/enrollments/${enrollmentId}/approve/`);
    return response.data;
}

export const rejectStudentEnroll = async (enrollmentId: number) => {
    const response = await apiClient.post(`/api/batch/enrollments/${enrollmentId}/reject/`);
    return response.data;
}

export const toggleBatchPublish = async (batchId: number) => {
    const response = await apiClient.patch(`/api/batch/batches/${batchId}/toggle_publish/`);
    return response.data;
};


export const createBatchFolder = async (batchId: number, folderData: {
    title: string;
    parent: number | null;
}): Promise<FolderResponse> => {
    const response = await apiClient.post<FolderResponse>(`/api/batch/content/${batchId}/create-folder/`, folderData);
    return response.data;
};
export const uploadBatchFile = async (batchId: number, parentFolderId: number | null, file: any): Promise<FileResponse> => {
    const formData = new FormData();
    formData.append("url", file);

    const response = await apiClient.post<FileResponse>(`/api/batch/content/${batchId}/folders/${parentFolderId}/create-file/`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return response.data;
};
export const renameBatchFolder = async (batchId: number, folderId: number, newTitle: string): Promise<FolderResponse> => {
    const response = await apiClient.patch<FolderResponse>(`/api/batch/content/${batchId}/folders/${folderId}/rename-folder/`, {
        title: newTitle,
    });
    return response.data;
};
export const renameBatchFile = async (batchId: number, fileId: number, newTitle: string): Promise<FileResponse> => {
    const response = await apiClient.patch<FileResponse>(`/api/batch/content/${batchId}/files/${fileId}/rename-file/`, {
        title: newTitle,
    });
    return response.data;
};
export const deleteBatchFolder = async (batchId: number, folderId: number): Promise<void> => {
    await apiClient.delete(`/api/batch/content/${batchId}/folders/${folderId}/delete-folder/`);
};
export const deleteBatchFile = async (batchId: number, fileId: number): Promise<void> => {
    await apiClient.delete(`/api/batch/content/${batchId}/files/${fileId}/delete-file/`);
};
export const toggleLockBatchFile = async (batchId: number, fileId: number): Promise<FileResponse> => {
    const response = await apiClient.patch<FileResponse>(`/api/batch/content/${batchId}/files/${fileId}/toggle-lock-file/`);
    return response.data;
};

export const updateBatchOrder = async (batchId:number, itemId:number, type:string, direction:string,) => {
    const response = await apiClient.patch(`/api/batch/content/${batchId}/update-order/`, {
        type,
        direction,
        id: itemId,
    });
    return response.data; // Now only contains the status message
};

export const getBatchFolderStructure = async (batchId: number, params: any): Promise<GetBatchFolderStructureResponse> => {
    const response = await apiClient.get<GetBatchFolderStructureResponse>(`/api/batch/batches/${batchId}/folder-structure/`, {params: params});
    return response.data;
};


export const createOfflineClassForBatch = async (values: any) => {
    const response = await apiClient.post(`/api/batch/offline-classes/`, values);
    return response.data;
}

export const deleteOfflineClassForBatch = async (classId: number, scheduleId:number) => {
    const response = await apiClient.delete(`/api/batch/offline-classes/${classId}?schedule=${scheduleId}`);
    return response.data;
}

export const addFeesRecordBatch = async (values: any) => {
    const response = await apiClient.post(`/api/batch/add-fees-record/`, values);
    return response.data;
}
