import {DataNode} from "antd/es/tree";
import styles from "./directoytreestructure.module.scss";
import {FileOutlined, FolderOutlined, LockOutlined, UnlockOutlined} from "@ant-design/icons";
import {Button, Image, Modal, Tree, Typography} from "antd";
import React, {useMemo, useState} from "react";
import { BASE_URL } from "../utils/apiClient";

const {Text} = Typography;

export interface FileItem {
    id: number;
    title: string;
    url: string; // URL to the file
    is_locked: boolean;
}

export interface FolderItem {
    id: number;
    title: string;
    files: FileItem[];
    subfolders: FolderItem[];
}

export interface Course {
    content: FolderItem[];
    duration: string; // e.g., "1 Year"
}

export interface SelectedFile {
    type: 'image' | 'video';
    src: string;
}

export interface DirectoryTreeStructureProps {
    directory: FolderItem[];
}

export interface CourseContentProps {
    course: Course;
}

const DirectoryTreeStructure: React.FC<DirectoryTreeStructureProps> = ({directory}) => {
    const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);


    // Function to handle file clicks
    const handleFileClick = (file: FileItem) => {
        console.log(file.url,"file.url")
        const fileExtension = file.url.split('.').pop()?.toLowerCase() || '';
        if (['png', 'jpg', 'jpeg', 'gif'].includes(fileExtension)) {
            setSelectedFile({type: 'image', src: file.url});
            setIsModalVisible(true);
        } else if (['mp4', 'mov', 'avi'].includes(fileExtension)) {
            setSelectedFile({type: 'video', src: file.url});
            setIsModalVisible(true);
        } else {
            window.open(BASE_URL+file.url, '_blank');
        }
    };

    // Function to close the modal
    const handleModalClose = () => {
        setSelectedFile(null);
        setIsModalVisible(false);
    };
    const convertToTreeData = (folders: FolderItem[]): DataNode[] => {
        return folders.map((folder) => ({
            key: `folder-${folder.id}`,
            title: (
                <div className={styles.treeTitle}>
                    <div className={styles.iconWrapper}>
                        <FolderOutlined className={styles.folderIcon}/>
                    </div>
                    <Text strong>{folder.title}</Text>
                </div>
            ),
            // icon: <FolderOutlined/>, // Optionally, you can remove this if using title icons
            children: [
                // Add files as Tree Nodes
                ...folder.files.map((file) => ({
                    key: `file-${file.id}`,
                    title: (
                        <div className={styles.treeTitle}>
                            <div className={styles.iconWrapper}>
                                {file.is_locked ? (
                                    <>
                                        <LockOutlined style={{color: 'red'}}/>
                                        <FileOutlined className={styles.fileIcon}/>
                                    </>
                                ) : (
                                    <>
                                        <UnlockOutlined style={{color: 'green'}}/>
                                        <FileOutlined className={styles.fileIcon}/>
                                    </>
                                )}
                            </div>
                            {file.is_locked ? (
                                <Text className={styles.lockedText}>{file.title}</Text>
                            ) : (
                                <Button
                                    type="link"
                                    onClick={() => handleFileClick(file)}
                                    className={styles.fileButton}
                                >
                                    <Text className={styles.unlockedText}>{file.title}</Text>
                                </Button>
                            )}
                        </div>
                    ),
                    // icon: <FileOutlined/>, // Optionally, you can remove this if using title icons
                    disableCheckbox: true, // Disable selection
                    selectable: false, // Disable default selection behavior
                })),
                // Recursively add subfolders
                ...convertToTreeData(folder.subfolders),
            ],
        }));
    };
    // Generate Tree Data
    const treeData: DataNode[] = useMemo(() => convertToTreeData(directory), [directory]);
    return (
        <>
            <Tree
                treeData={treeData}
                defaultExpandAll
                showIcon
                selectable={false} // Disable selection of folders
                style={{userSelect: 'none'}} // Prevent text selection
            />
            <Modal open={isModalVisible} className={styles.contentViewModal} footer={null} onCancel={handleModalClose} centered>
                {selectedFile?.type === 'image' ? (
                    <img src={`${BASE_URL}${selectedFile.src}`} alt="File"/>
                ) : selectedFile?.type === 'video' ? (
                    <video src={`${BASE_URL}${selectedFile.src}`} controls style={{width: '100%'}}/>
                ) : null}
            </Modal>
        </>)
}

export default DirectoryTreeStructure;
