import useFetchList from "../../../utils/listClient";
import apiClient from "../../../utils/apiClient";
import {BatchAttendanceProps, BatchLiveClassesProps, BatchProps} from "../../../types/batch";

export const usePurchasedStudentBatchList = () => {
    return useFetchList<BatchProps>("/api/batch/student/purchased-batches/");
};

export const useAvailableStudentBatchList = () => {
    return useFetchList<BatchProps>("/api/batch/student/available-batches/");
};

export const purchaseDetailStudentBatch = async (batchId: string) => {
    const response = await apiClient.get<BatchProps>(`/api/batch/student/purchased-batches/${batchId}/`);
    return response.data;
};

export const availableDetailStudentBatch = async (batchId: string) => {
    const response = await apiClient.get(`/api/batch/student/available-batches/${batchId}/`);
    return response.data;
};

export const useStudentBatchLiveClasses = (batchId:number) => {
    return useFetchList<BatchLiveClassesProps>(`/api/batch/student/${batchId}/batches-live-classes/`);
};


export const studentJoinBatchRequest = async (values:any) => {
    const response = await apiClient.post(`/api/batch/student/join-batch/`, values);
    return response.data;
};
export const useStudentBatchAttendance = (batchId:number) => {
    return useFetchList<BatchAttendanceProps>(`/api/batch/student/${batchId}/batches-attendance/`);
};

export const manageFaculty = async (batchId:number, facultyId: number, action: string) => {
    const response = await apiClient.post(`/api/batch/batches/${batchId}/manage-faculty/`, {
        faculty_id: facultyId,
        action: action
    });
    return response.data;
};

export const getStudentBatchClasses = async () => {
    const response = await apiClient.get(`/api/batch/student/batch-classes/`);
    return response.data;
};
