import React from 'react';
import {Card, Table, Tag} from 'antd';
import styles from "./offline.module.scss";
import {BatchProps} from "../../types/batch";

interface OfflineClassesProps {
    batch: BatchProps;
}

const OfflineClasses: React.FC<OfflineClassesProps> = ({batch}) => {
    const batchClassesColumns = [
        {
            title: 'Batch',
            dataIndex: ['class', 'batch'],
            key: 'batch'
        },
        {
            title: 'Faculty',
            dataIndex: ['class', 'faculty'],
            key: 'faculty'
        },
        {
            title: 'Class Type',
            dataIndex: ['class', 'class_type'],
            key: 'class_type',
            render: (class_type: string) => (
                <Tag color={class_type === 'regular' ? 'blue' : 'green'}>
                    {class_type === 'regular' ? 'Regular Class' : 'One-Time Class'}
                </Tag>
            )
        },
        {
            title: 'Time Slot (Day)',
            dataIndex: 'time_slot',
            key: 'time_slot',
            render: (time_slot: string) => (
                <Tag color="blue">{time_slot}</Tag>
            )
        },
        {
            title: 'Schedules',
            key: 'schedules',
            render: (_: any, record: any) => (
                <Table
                    dataSource={record.schedules}
                    pagination={false}
                    rowKey="id"
                    columns={[
                        {
                            title: 'Start Time',
                            dataIndex: 'start_time',
                            key: 'start_time'
                        },
                        {
                            title: 'End Time',
                            dataIndex: 'end_time',
                            key: 'end_time'
                        }
                    ]}
                    bordered
                />
            )
        }

    ];


    return (
        <Card>
            <div className={styles.liveMainDiv}>
                <div className={styles.leftSection}>
                    <Card className={styles.noVideoCard}>
                        <Table
                            columns={batchClassesColumns}
                            pagination={false}
                            dataSource={batch.offline_classes}  // Map the data to the structure expected by the Table
                        />
                    </Card>
                </div>
            </div>
        </Card>
    );
};

export default OfflineClasses;
